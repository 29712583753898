// const GoogleClientId =
//   "293159370745-r29hbdkmsa54inornuvqak4ugpg7fqcs.apps.googleusercontent.com";

//const GoogleClientId =
//  "351945527250-3saji582t42ltplpq1f6e36el817lqt4.apps.googleusercontent.com";

//defined by Haseeb
// const GoogleClientId =
//   "53658718375-8bfhipm59b47jmuubsskk28ljtv9a9hj.apps.googleusercontent.com";
const GoogleClientId =
  "53658718375-punsso6gdqo3bsmqgcd62fe1qtabc8fu.apps.googleusercontent.com";
// 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
export default GoogleClientId;
