import GoogleStreetview from "react-google-streetview";
import Geocode from "react-geocode";
import { useEffect, useState } from "react";
// import { LoadScript } from "@react-google-maps/api";

const StreetView = (props) => {
  const address = props.address;
  const [loadStreet, setLoad] = useState(false);
  const googleMapsApiKey = "AIzaSyA4bN_JLbgMsrsaspEm1ebHDiTNNvE7DTA";
  const [optionsState, setOptions] = useState({
    position: {
      lat: 51.5157332,
      lng: -0.1377451,
    },
    pov: { heading: 0, pitch: 0 },
    zoom: 0,
  });
  useEffect(() => {
    Geocode.setApiKey(googleMapsApiKey);
    Geocode.setLanguage("en");
    Geocode.setRegion("uk");
    /**
     *  GeoCode to get the location from the google
     *
     * */
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const myLatLng = response.results[0].geometry.location;

        let newLng, newLat, newheading;
        let LatLng;

        // console.log("address from the Mapbox Rec", address);

        const google = window.google;
        /**
         *  Using Direction service to get the Driver View from the point of location
         * */
        const directionsService = new google.maps.DirectionsService();
        directionsService.route(
          {
            origin: address,
            destination: address,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              LatLng = result.routes[0].legs[0].start_location;

              /***
               *  gets the results of the langitude and latitude from the start of the position as A driver and using it in
               *
               * but fails on Holland & Barrett on arch branch
               * **/

              const ultra_lat = LatLng.lat();
              const ultra_lng = LatLng.lng();

              /**
               *  Use of Street View Service to get the panaroma shot
               * */

              var sv = new google.maps.StreetViewService();
              const radius = 100;
              sv.getPanorama(
                {
                  location: LatLng,
                  preference: google.maps.StreetViewPreference.NEAREST,
                  radius: radius,
                  source: google.maps.StreetViewSource.OUTDOOR,
                },
                function (g, t) {
                  if (t == google.maps.StreetViewStatus.OK) {
                    newLng = g.location.latLng.lng();
                    newLat = g.location.latLng.lat();
                  }
                  /**
                   *  Computing the heading as the rotation for the streetView
                   * */

                  newheading = google.maps.geometry.spherical.computeHeading(
                    g.location.latLng,
                    myLatLng
                  );

                  setOptions({
                    ...optionsState,
                    pov: { heading: newheading, pitch: 0 },
                    position: {
                      lat: newLat,
                      lng: newLng,
                      // lat: ultra_lat,
                      // lng: ultra_lng,
                      // lat: lat,
                      // lng: lng,
                    },
                  });

                  setLoad(true);
                }
              );
            } else {
              console.error("error fetching directions", result, status);
            }
          }
        );
      },

      (error) => {
        console.error(error);
      }
    );
  }, []);

  return (
    <div className="row">
      <div className="text-center">
        <div
          className=""
          style={{
            width: "800px",
            height: "450px",
            backgroundColor: "#eeeeee",
          }}
        >
          {loadStreet == true ? (
            <GoogleStreetview
              apiKey={googleMapsApiKey}
              streetViewPanoramaOptions={optionsState}
            ></GoogleStreetview>
          ) : (
            <div>Loading ...</div>
          )}
        </div>
      </div>
    </div>
  );
};

StreetView.defaultProps = {
  lat: 51.515081,
  lng: -0.1462983,
  address: "161, 167 Oxford St, London W1D 2JP, United Kingdom",
};
export default StreetView;
