import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import Modal from "react-modal";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import StreetView from "./streetView";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

mapboxgl.accessToken = `pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "0px",
    padding: "0px",

    transform: "translate(-50%, -50%)",
    borderRadius: "30px",
  },
};

export default function MapBox() {
  const [address, setAddress] = useState();

  let controller = null;
  let signal = null;

  const [dimensionViews, setDimViews] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let [markers, setMarkers] = useState([]);

  const [mapProps, setMapProps] = useState();
  const [MapNavigate, setMapNavigate] = useState();

  let mapCopy;
  const [views, setViews] = useState([
    { view: "Street View", pitch: 90 },
    { view: "Drone View", pitch: 60 },
    { view: "Top View", pitch: 0 },
  ]);
  const [viewCounter, setCounter] = useState(2);

  const [loading, setLoading] = useState(false);
  const [buildingData, SetBuilding] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ProfileIsOpen, setProfileIsOpen] = useState(false);

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-0.141);
  const [lat, setLat] = useState(51.51);
  const [zoom, setZoom] = useState(18);
  const state = useSelector((state) => state.user);

  function afterOpenModal() {
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!state.user) {
      // navigate(`/signIn`);
    }
    controller = new AbortController();
    signal = controller.signal;

    const map = new mapboxgl.Map({
      container: mapContainer.current,

      style:
        dimensionViews === true
          ? "mapbox://styles/haseebabbasi00/cl6nxm56n002p15qof1ujufem"
          : "mapbox://styles/haseebabbasi00/cl7dq8yd7000215octt1khn3o",
      width: "100wh",
      attributionControl: false,
      pitch: views[viewCounter].pitch,
      center: [-0.141099, 51.515419],
      zoom: 18,
      bearing: 80,
      layers: [
        {
          id: "background",
          type: "background",
          layout: {},
          paint: {
            "background-color": ["white"],
          },
        },
        {},
      ],
    });
    mapCopy = map;

    /**
     *   View Change Component:: 2d, 3d
     * */
    class PitchToggle {
      constructor({ bearing = -20, pitch = 0, minpitchzoom = null }) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
      }

      onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.type = "button";
        this._btn["aria-label"] = "Toggle Pitch";
        this._btn.onclick = function () {
          if (map.getPitch() === 0) {
            map.easeTo({ pitch: 80, bearing: 80 });

            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
          } else {
            map.easeTo({ pitch: 0, bearing: 80 });
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    /**
     *  Geocoder to Search all along with mapbox provided search box for places, buildings etc.
     * */

    /**
     * 
     const geocoder = new MapboxGeocoder({
       accessToken: mapboxgl.accessToken,
       mapboxgl: mapboxgl,
       });
     map.addControl(geocoder, "top-right");
    **/

    /**
     *  set the zoom rate of the mapbox
     * */
    map.scrollZoom.setZoomRate(1 / 500);
    map.scrollZoom.setWheelZoomRate(1 / 700);

    setMapNavigate(map);
    setMapProps(mapContainer.current);
    /**
     *  Adding the location Icons as the maps loads
     * */
    map.on("load", () => {
      map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/cat.png",
        (error, image) => {
          if (error) throw error;

          map.addImage("cat", image);
          map.addSource("point", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [-0.1304631464227839, 51.51641177208569],
                  },
                },
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [-0.14203501935114105, 51.51527717368762],
                  },
                },
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [-0.15779513234576825, 51.51350268739273],
                  },
                },
              ],
            },
          });

          map.addLayer({
            id: "points",
            type: "symbol",
            source: "point", // reference the data source
            layout: {
              "icon-image": "cat", // reference the image
              "icon-size": 0.15,
            },
          });
        }
      );
    });

    /**
     *
     *  Get the Building data on the hover of the building
     * */
    map.on("mousemove", (e) => {
      const point = e.lngLat;

      // `e.point` is the x, y coordinates of the `mousemove` event
      // relative to the top-left corner of the map.
      setLoading(true);
      setLng(point.lng);
      setLat(point.lat);

      var features = map.queryRenderedFeatures(e.point);
      controller.abort();

      controller = new AbortController();
      signal = controller.signal;

      // console.log("mouse enter");
      /**
       * checks the layer then fetches the building data on the hover on the building on the basis of lng,lat
       * */
      if (features[0]) {
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`,
          { signal }
        )
          .then((data) => data.json())
          .then((json) => {
            SetBuilding(json);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    });

    map.on("mouseenter", "building-extrusion", (e) => {
      // to get the point
      const point = e.lngLat;
      setLng(point.lng);
      setLat(point.lat);

      setLoading(true);
      var features = map.queryRenderedFeatures(e.point);
      controller.abort();

      controller = new AbortController();
      signal = controller.signal;

      // console.log("mouse enter");
      /**
       * checks the layer then fetches the building data on the hover on the building on the basis of lng,lat
       * */
      if (features[0]) {
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`,
          { signal }
        )
          .then((data) => data.json())
          .then((json) => {
            SetBuilding(json);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    });

    const nav = new mapboxgl.NavigationControl();

    map.addControl(nav, "bottom-right");
    const minpitchzoom = 11;
    /**
     * first param as control second as the position of the control like bottom-right
     * */
    map.addControl(
      new PitchToggle({ minpitchzoom: minpitchzoom }),
      "bottom-right"
    );

    /**
     *  Used for hovering the buildings and changing the color
     * */
    // map.on("style.load", function () {
    //   if (map.getSource("composite")) {
    //     map.addLayer(
    //       {
    //         id: "3d-buildings",
    //         source: "composite",
    //         "source-layer": "building",
    //         type: "fill-extrusion",
    //         minzoom: 14,
    //         paint: {
    //           "fill-extrusion-color": [
    //             "case",
    //             ["boolean", ["feature-state", "hover"], false],
    //             "#ff0000",
    //             "#ddd",
    //           ],
    //           "fill-extrusion-height": ["number", ["get", "height"], 5],
    //           "fill-extrusion-base": ["number", ["get", "min_height"], 0],
    //           "fill-extrusion-opacity": 1,
    //         },
    //       },
    //       "road-label"
    //     );
    //   }
    // });

    /**
     *  Get the building data
     * */
    map.on("click", (e) => {
      setProfileIsOpen(false);
      var features = map.queryRenderedFeatures(e.point);

      if (features[1]) {
        if (features[1].layer.id == "C100") {
          setLoading(true);
          const point = e.lngLat;

          fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`
          )
            .then((data) => data.json())
            .then((json) => {
              SetBuilding(json);
              setLoading(false);
              setIsOpen(true);
            })
            .catch((e) => {
              setLoading(false);
            });
        }
      }
      if (features[0]) {
        if (features[0].layer.id == "C100") {
          setLoading(true);
          const point = e.lngLat;

          fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`
          )
            .then((data) => data.json())
            .then((json) => {
              SetBuilding(json);
              setLoading(false);
              setIsOpen(true);
            })
            .catch((e) => {
              setLoading(false);
            });
        }
      }
    });
    /**
     *  Changes the movement of the map as info into states lng,lat zoom
     * */
    map.on("move", (e) => {
      setLng(map.getCenter().lng);
      setLat(map.getCenter().lat);
      setZoom(map.getZoom().toFixed(4));
    });

    //
    return () => {
      map.remove();
    };
  }, [dimensionViews]);

  const goToPlace = (place) => {
    {
      /**
       *  markers and flying to view searched building
       * */

      const popUp = new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(`<h3>${place.name}</h3><p>${place.formatted_address}</p>`);
      markers.forEach((marker) => marker.remove());
      const el = document.createElement("div");
      el.className = "marker";
      const marker = new mapboxgl.Marker(el)
        .setLngLat([
          place.geometry.location.lng(),
          place.geometry.location.lat(),
        ])
        .setPopup(popUp)
        .addTo(mapProps == null ? mapCopy : mapProps);

      markers.push(marker);
      mapProps == null
        ? mapCopy.flyTo({
            pitch: 0,
            zoom: 18,
            center: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          })
        : mapProps.flyTo({
            pitch: 0,
            zoom: 18,
            center: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          });
    }
  };

  return (
    <>
      <>
        <div>
          <div className="container text-center">
            <div className="row">
              <div className="col-md-1 col-xs-5 offset-xs-1">
                <button className="btn-main" onClick={handleClickOpen}>
                  Buildings Area
                </button>
              </div>
              <div className="col-md-1 offset-md-1 col-xs-5 offset-xs-1">
                <button
                  className="btn-main"
                  onClick={() => {
                    setDimViews(!dimensionViews);
                  }}
                >
                  2D/3D views
                </button>
              </div>

              <div className="col-md-6 offset-md-3 col-xs-12">
                <div>
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyBxZ5mUOwo3CUldbWrsKCZyeVJVffyP8AU"
                    minLengthAutocomplete={3}
                    onChange={() => {
                      // alert("changes");
                    }}
                    selectProps={{
                      isClearable: true,
                      value: address,
                      onChange: (val) => {
                        setAddress(address);

                        const func = async () => {
                          const geocodeObj =
                            val &&
                            val.value &&
                            (await geocodeByPlaceId(val.value.place_id));
                          if (geocodeObj) {
                            markers.forEach((marker) => marker.remove());

                            const place = geocodeObj[0];
                            // console.log("place from geocode", place);
                            // console.log(mapCopy);
                            // console.log(mapProps);
                            /**
                             *  markers and flying to view searched building
                             * */
                            const popUp = new mapboxgl.Popup({ offset: 25 }) // add popups
                              .setHTML(
                                `<h3>${place.types[0]}</h3><p>${place.formatted_address}</p>`
                              );
                            const el = document.createElement("div");
                            el.className = "marker";
                            const marker = new mapboxgl.Marker(el)
                              .setLngLat([
                                place.geometry.location.lng(),
                                place.geometry.location.lat(),
                              ])
                              .setPopup(popUp)
                              .addTo(MapNavigate);

                            setMarkers([...markers, marker]);

                            MapNavigate.flyTo({
                              pitch: 0,
                              zoom: 18,
                              center: [
                                place.geometry.location.lng(),
                                place.geometry.location.lat(),
                              ],
                            });
                            setAddress("");
                            /**
                             *  markers and flying to view searched building
                             * */
                          }
                        };

                        // console.log(mapCopy);

                        func();
                      },
                    }}
                  />
                </div>
                {
                  /**
                   *
                   *  AutoComplete old one
                   * */
                  //   <Autocomplete
                  //   className="form-control mt-3"
                  //   apiKey={"AIzaSyBxZ5mUOwo3CUldbWrsKCZyeVJVffyP8AU"}
                  //   onPlaceSelected={(place) => goToPlace(place)}
                  //   options={options}
                  // />
                }
              </div>
            </div>
          </div>
        </div>
        <div>
          {buildingData && (
            <div className="sidebar">
              {loading === true
                ? "Loading ..."
                : buildingData.features[0].place_name}
            </div>
          )}
          <div className="sidebar_2">
            Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom:{" "}
            {zoom}
            {/* | Pitch {pitch} */}
          </div>

          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Info Modal"
          >
            {ProfileIsOpen === true ? (
              <>
                <StreetView
                  address={buildingData.features[0].place_name}
                  lngProps={buildingData.features[0].center[0]}
                  latProps={buildingData.features[0].center[1]}
                />
              </>
            ) : (
              <div className="custom-shadow">
                <div className="text-center">
                  <h3>Property Details</h3>
                </div>
                <div className="container-fluid">
                  <div className="text-center ">
                    <div
                      style={{
                        borderRadius: "1rem",
                      }}
                      className="card custom-shadow"
                    >
                      <div
                        className="card-body "
                        style={{
                          borderRadius: "1rem",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {loading === true ? (
                          <div style={{ fontSize: "5rem" }}>
                            <CircularProgress color="secondary" />
                          </div>
                        ) : (
                          <>
                            {buildingData !== undefined && (
                              <div>
                                <div
                                  className="row"
                                  style={{
                                    borderRadius: "1rem",
                                    backgroundColor: "white",
                                    padding: "0px",
                                    margin: "0px",
                                    backgroundColor: "#e3c5b19c",
                                  }}
                                >
                                  <div className="col-12">
                                    Building Name :{" "}
                                    <span
                                      style={{
                                        color: "blueviolet",
                                        fontSize: "1.7em",
                                      }}
                                    >
                                      {
                                        buildingData.features[0].place_name.split(
                                          ","
                                        )[0]
                                      }
                                    </span>
                                  </div>
                                  <div className="col-12">
                                    Building Area :{" "}
                                    <span
                                      style={{
                                        color: "blueviolet",
                                        fontSize: "1em",
                                      }}
                                    >
                                      {`${
                                        buildingData.features[0].place_name.split(
                                          ","
                                        )[1]
                                      } , ${
                                        buildingData.features[0].place_name.split(
                                          ","
                                        )[2]
                                      }`}
                                    </span>
                                  </div>
                                  <div className="col-12">
                                    Building Type :{" "}
                                    <span
                                      style={{
                                        color: "blueviolet",
                                        fontSize: "1em",
                                      }}
                                    >
                                      {`${buildingData.features[0].properties.category}  `}
                                    </span>
                                  </div>

                                  <div className="col-12">
                                    {/* Country : */}{" "}
                                    {`${
                                      buildingData.features[0].place_name.split(
                                        ","
                                      )[3]
                                    } , ${
                                      buildingData.features[0].place_name.split(
                                        ","
                                      )[4]
                                    }`}
                                  </div>
                                  <div
                                    onClick={() => {
                                      // setProfileIsOpen(true);
                                    }}
                                    className="col-12 font-meri"
                                    style={{
                                      backgroundColor: "white",
                                      color: "purple",
                                      marginTop: "1rem",
                                      marginBottom: "1.2rem",
                                    }}
                                  >
                                    Property Owner :
                                    <span
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      {" GameRee"}
                                    </span>
                                  </div>

                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-6 border border-dark">
                                        <span> 1,235</span>
                                      </div>
                                      <div className="col-6 border border-dark">
                                        <span> 9</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="col-12"
                                    style={{ color: "blue", fontSize: "2rem" }}
                                  >
                                    <div className="d-flex justify-content-around  my-3">
                                      <button
                                        className=" btn-main"
                                        onClick={() => {
                                          setProfileIsOpen(true);
                                        }}
                                      >
                                        Street View
                                      </button>
                                      <button className=" btn-main">
                                        Buy NFT
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-center my-1">
                    <AiOutlineCloseCircle
                      style={{ fontSize: "2rem" }}
                      onClick={closeModal}
                    />
                    {/* Close */}
                    {/* </button> */}
                  </div>
                </div>
              </div>
            )}
          </Modal>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Select   Buildings  Areas "}
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItem
                  onClick={() => {
                    MapNavigate.flyTo({
                      pitch: 0,
                      zoom: 18,
                      center: [-0.1304631464227839, 51.51641177208569],
                    });
                    setOpen(false);
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary="1. Oxford Street, London," />
                  </ListItemButton>
                </ListItem>

                <ListItem
                  onClick={() => {
                    MapNavigate.flyTo({
                      pitch: 0,
                      zoom: 18,
                      center: [-0.14203501935114105, 51.51527717368762],
                    });

                    setOpen(false);
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary="2. Oxford Street, London," />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => {
                    MapNavigate.flyTo({
                      pitch: 0,
                      zoom: 18,
                      center: [-0.15779513234576825, 51.51350268739273],
                    });
                    setOpen(false);
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText primary="3. Oxford Street, London," />
                  </ListItemButton>
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>

          <div
            style={{ height: "100vh", marginTop: "10px" }}
            ref={mapContainer}
            className="map-container"
          />
        </div>
      </>
    </>
  );
}
